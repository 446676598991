import { formatErrorMessage } from "./error-utils";
import {
  CardError,
  ComplianceError,
  GatewayTimeoutError,
  InternalServerError,
  NotFoundError,
  PaymentRequiredError,
  PromotionCodeError,
  RecaptchaError,
  ResponseNotOkError,
  ResultAlreadyPaidError,
  ServiceUnavailableError,
  SetupIntentNotSucceededError,
  UnprocessableEntityError,
} from "./errors";
import { ErrorResponse, StatusHandler } from "./types";

const statusHandlers: Record<number, StatusHandler> = {
  402: handlePaymentErrors,
  404: () => {
    throw new NotFoundError();
  },
  422: handleUnprocessableEntity,
  500: () => {
    throw new InternalServerError();
  },
  503: () => {
    throw new ServiceUnavailableError();
  },
  504: () => {
    throw new GatewayTimeoutError();
  },
};

export async function handleResponseError(res: Response): Promise<void> {
  const handler = statusHandlers[res.status] as StatusHandler | undefined;
  if (handler) {
    await handler(res);
  } else {
    throw new ResponseNotOkError(res.status);
  }
}

async function handleUnprocessableEntity(res: Response): Promise<never> {
  const errorResponse = (await res.json()) as ErrorResponse;
  const errorMessage = formatErrorMessage(errorResponse);

  errorResponse.errors?.forEach((error) => {
    const pointer = error.source?.pointer;

    switch (pointer) {
      case "/data/attributes/recaptcha_token":
        throw new RecaptchaError(errorMessage);
      case "/data/attributes/compliance_result":
        throw new ComplianceError(errorMessage);
      case "/data/attributes/promotion_code_id":
        throw new PromotionCodeError(errorMessage);
      case "/data/attributes/result_already_paid":
        throw new ResultAlreadyPaidError(errorMessage);
      case "/data/attributes/setup_intent_not_succeeded":
        throw new SetupIntentNotSucceededError(errorMessage);
      default:
        throw new UnprocessableEntityError(errorMessage);
    }
  });

  throw new UnprocessableEntityError(errorMessage);
}

async function handlePaymentErrors(res: Response): Promise<never> {
  const errorResponse = (await res.json()) as ErrorResponse;
  const errorMessage = formatErrorMessage(errorResponse);

  errorResponse.errors?.forEach((error) => {
    const pointer = error.source?.pointer;
    if (pointer) {
      switch (pointer) {
        case "/data/attributes/card_error":
          throw new CardError(errorMessage);
      }
    }
  });

  throw new PaymentRequiredError(errorMessage);
}
