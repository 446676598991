import { ErrorResponse } from "./types";

export function formatErrorMessage(errorResponse: ErrorResponse): string {
  if (
    errorResponse.errors &&
    Array.isArray(errorResponse.errors) &&
    errorResponse.errors.length > 0
  ) {
    return errorResponse.errors.map((err) => err.title).join(", ");
  }

  if (errorResponse.error) {
    return errorResponse.error;
  }

  return "No details were provided";
}
