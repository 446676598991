import { useRecoilValue, useSetRecoilState } from "recoil";

import { getAnonymousId } from "../../utils/analytics";
import { getCookieValue, getCurrentURLSearchParams, getWindow } from "../../utils/browser-features";
import { handleFindUserErrors } from "../../utils/error-utils/catch-error-handlers";
import { findUser, getMetaFbc, getMetaFbp } from "../data/analytics-repository";
import { trackingParamsState } from "../state/analytics-state";

type Analytics = {
  reset: () => void;
  load: (writeKey: string) => void;
  identify: (
    userId: string,
    user: Record<string, unknown>,
    options: { anonymousId: string }
  ) => void;
};

export const useSetupAnalytics = async (): Promise<void> => {
  const w: Window | null = getWindow();
  const writeKey = process.env.GATSBY_SEGMENT_WRITE_KEY;
  const currentURLSearchParams = getCurrentURLSearchParams();

  if (!w || !writeKey) {
    return;
  }

  const analytics = w.analytics as Analytics;
  const ajsUserId = getCookieValue("ajs_user_id");
  const petParentKbKey = currentURLSearchParams?.get("pet_parent_kb_key");
  const userId = ajsUserId ? ajsUserId : petParentKbKey;
  const anonymousId = getAnonymousId();

  analytics.reset();

  if (!userId) {
    analytics.load(writeKey);

    return;
  }

  try {
    const user = await findUser(userId);

    analytics.identify(userId, user, { anonymousId });
    analytics.load(writeKey);
  } catch (error) {
    handleFindUserErrors(error as Error, "setupAnalytics", userId);

    analytics.load(writeKey);
  }
};

export const useGetTrackingParams = (): (() => void) => {
  const trackingParamsSetter = useSetRecoilState(trackingParamsState);
  const metaFbp = getMetaFbp();
  const metaFbc = getMetaFbc();

  return (): void => {
    trackingParamsSetter({ metaFbp, metaFbc });
  };
};

export const useTrackingParams = (): Record<string, unknown> | null => {
  return useRecoilValue(trackingParamsState);
};
