import Rollbar, { Payload } from "rollbar";

interface ExtendedPayload extends Payload {
  custom?: {
    fingerprint?: string;
    context?: string;
  };
}
const transformPayload = (payload: ExtendedPayload) => {
  payload.custom?.fingerprint && (payload.fingerprint = payload.custom.fingerprint);
  payload.custom?.context && (payload.context = payload.custom.context);
};

const config = {
  accessToken: process.env.GATSBY_ROLLBAR_ACCESS_TOKEN,
  environment: process.env.NODE_ENV,
  captureUncaught: true,
  captureUnhandledRejections: true,
  payload: {
    environment: process.env.NODE_ENV,
  },
  transform: transformPayload,
  uncaughtErrorLevel: "warning" as Rollbar.Level,
};

export type RollbarLogArgument = Rollbar.LogArgument;

export const rollbar = new Rollbar(config);
